<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: {
    type: { type: String, default: "" },
  },
  computed: {
    classes() {
      return {
        "ka-panel": true,
        [`ka-panel--${this.type}`]: true,
      };
    },
  },
};
</script>
