<template>
  <div :style="{ height: options.height, width: options.width }">
    <LMap ref="map" :options="mapOptions" style="height: 100%">
      <LTileLayer :url="url" :attribution="attribution" />
      <LMarker
        v-for="item in geoItems"
        :key="item.post_id"
        :lat-lng="markerLatLng(item._geoloc)"
        @mouseover="() => markerOver(item)"
        @mouseout="() => markerOut(item)"
      >
        <LIcon
          :icon-url="iconUrl(item)"
          :icon-retina-url="iconUrl(item)"
          :icon-size="options.marker.iconSize"
          :icon-anchor="options.marker.iconAnchor"
          :popup-anchor="[0, -20]"
          :shadow-url="shadowUrl"
        />
        <LPopup>
          <div class="ka-popup">
            <div
              v-if="item.images.thumbnail"
              v-lazy:background-image="item.images.large.url"
              class="ka-popup__image"
            />
            <div class="ka-popup__title">{{ item.post_title }}</div>
            <div class="ka-popup__location">
              <img :src="`${baseUrl}/images/map-marker.svg`" />
              <span
                v-for="(location, index) in item.taxonomies.global_lokation"
                :key="location"
                ><span v-if="index > 0">, </span
                ><a @click.stop="hitLocationClick(location)">{{
                  location
                }}</a></span
              >
            </div>
            <div class="ka-popup__description" v-html="item.post_excerpt" />
            <div class="ka-popup__link" @click="hitClick(item)">
              <img :src="`${baseUrl}/images/hit-arrow.svg`" />{{
                linkTitle(item)
              }}
            </div>
          </div>
        </LPopup>
      </LMarker>
    </LMap>
  </div>
</template>

<script>
import slugify from "slugify";
import { latLng } from "leaflet";
import { LIcon, LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { createWidgetMixin } from "vue-instantsearch";
import { connectGeoSearch } from "instantsearch.js/es/connectors";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
  },
  mixins: [createWidgetMixin({ connector: connectGeoSearch })],
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    baseUrl: {
      type: String,
      default: "",
    },
    hitOverId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
  computed: {
    items() {
      if (this.state && this.state.items) {
        return this.state.items;
      }
      return [];
    },
    geoItems() {
      if (this.items.length > 0) {
        return this.items.filter((item) => item._geoloc);
      }
      return [];
    },
    coords() {
      return this.geoItems.map((item) => {
        return [item._geoloc.lat, item._geoloc.lng];
      });
    },
    bounds() {
      let p1x = (this.coords[0] && this.coords[0][0]) || 0;
      let p1y = (this.coords[0] && this.coords[0][1]) || 0;
      let p2x = (this.coords[0] && this.coords[0][0]) || 0;
      let p2y = (this.coords[0] && this.coords[0][1]) || 0;

      this.coords.forEach((coord) => {
        p1x = Math.min(coord[0], p1x);
        p1y = Math.min(coord[1], p1y);
        p2x = Math.min(coord[0], p2x);
        p2y = Math.min(coord[1], p2y);
      });

      return { p1x, p1y, p2x, p2y };
    },
    shadowUrl() {
      return this.options.marker.shadow.length &&
        this.options.marker.shadow.length > 1
        ? `${this.baseUrl}/images/${this.options.marker.shadow}`
        : null;
    },
  },
  watch: {
    bounds(newVal, oldVal) {
      if (
        (newVal.p1x !== oldVal.p1x ||
          newVal.p1y !== oldVal.p1y ||
          newVal.p2x !== oldVal.p2x ||
          newVal.p2y !== oldVal.p2y) &&
        newVal.p1x + newVal.p1y + newVal.p2x + newVal.p2y > 0
      ) {
        this.$refs.map.mapObject.fitBounds(this.coords, {
          padding: this.options.padding,
          maxZoom: this.options.maxZoom,
        });
      }
    },
  },
  methods: {
    markerOver(item) {
      this.$emit("hit-over", item.post_id);
    },
    markerOut() {
      this.$emit("hit-out");
    },
    markerLatLng(geoLoc) {
      return latLng(geoLoc.lat, geoLoc.lng);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    isMarkerOver(item) {
      return item.post_id === this.hitOverId;
    },
    iconUrl(item) {
      if (this.isMarkerOver(item)) {
        return `${this.baseUrl}/images/${this.options.marker.active}`;
      }
      return `${this.baseUrl}/images/${this.options.marker.normal}`;
    },
    linkTitle(item) {
      return item.acf_det_sker_link && item.acf_det_sker_link.length > 0
        ? this.options.dictionary.visitWebsite
        : this.options.dictionary.readMore;
    },
    hitClick(item) {
      if (item.acf_det_sker_link) {
        window.open(item.acf_det_sker_link, "_blank");
      } else {
        window.location.href = item.permalink;
      }
    },
    hitLocationClick(location) {
      window.location.href = `/global-lokation/${slugify(location)}`;
    },
  },
};
</script>
