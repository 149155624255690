<template>
  <div class="ka-slider-range">
    <AisRangeInput v-bind="aisOptions">
      <div slot-scope="{ refine }" style="width: 100%">
        <VueSlider
          v-model="value"
          :min="aisOptions.min"
          :max="aisOptions.max"
          :lazy="true"
          v-bind="options"
          @change="
            (value) => {
              valueChange(value, refine);
            }
          "
        />
      </div>
    </AisRangeInput>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "SliderRange",
  components: { VueSlider },
  props: {
    aisOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return { value: [this.aisOptions.min, this.aisOptions.max] };
  },
  watch: {
    query: {
      handler: function (query) {
        this.stateFromQuery(query);
      },
      immediate: true,
    },
  },
  mounted() {
    this.stateFromQuery(this.query);
  },
  methods: {
    stateFromQuery(query) {
      const range =
        query && query.range && query.range[this.aisOptions.attribute];

      if (range) {
        const split = range.split(":");
        this.value = [parseInt(split[0]), parseInt(split[1])];
      } else {
        this.value = [this.aisOptions.min, this.aisOptions.max];
      }
    },
    valueChange(event, refine) {
      refine({ min: event[0], max: event[1] });
    },
  },
};
</script>
