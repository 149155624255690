<template>
  <AisStats>
    <div
      slot-scope="{ nbHits }"
      class="ka-stats"
      v-html="formatLabel(nbHits)"
    />
  </AisStats>
</template>

<script>
export default {
  name: "Stats",
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    formatLabel(hits) {
      const label =
        hits === 1 ? this.options.label.singular : this.options.label.plural;
      return label.replace(
        "{{hits}}",
        `<span class="ka-stats__hits">${hits}</span>`
      );
    },
  },
};
</script>
