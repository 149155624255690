<template>
  <div :class="classes">
    <div v-if="isTitleVisible" class="ka-group__title">
      {{ title }}
    </div>
    <div class="ka-group__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Group",
  props: {
    className: { type: String, default: "" },
    title: { type: String, default: "" },
  },
  computed: {
    classes() {
      return {
        "ka-group": true,
        [this.className]: this.className.length > 0,
      };
    },
    isTitleVisible() {
      return this.title.length > 0;
    },
  },
};
</script>
