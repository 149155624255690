<template>
  <li
    :class="{ 'ka-hit': true, 'ka-hit--over': isOver }"
    @mouseover="hitOver"
    @mouseout="$emit('hit-out')"
    @click="hitClick"
  >
    <div
      v-if="item.images.thumbnail"
      v-lazy:background-image="item.images.large.url"
      class="ka-hit__image"
    />
    <div class="ka-hit__content">
      <div v-if="dateString" class="ka-hit__date">{{ dateString }}</div>
      <div class="ka-hit__title">{{ item.post_title }}</div>
      <div class="ka-hit__location">
        <img :src="`${baseUrl}/images/map-marker.svg`" />
        <span
          v-for="(location, index) in item.taxonomies.global_lokation"
          :key="location"
          ><span v-if="index > 0">, </span
          ><a @click.stop="hitLocationClick(location)">{{ location }}</a></span
        >
      </div>
      <div class="ka-hit__description" v-html="item.post_excerpt" />
      <div class="ka-hit__link" @click="hitClick">
        <img :src="linkIcon" />{{ linkTitle }}
      </div>
    </div>
  </li>
</template>

<script>
import slugify from "slugify";
export default {
  name: "Hit",
  props: {
    baseUrl: { type: String, default: "" },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isOver: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkTitle() {
      return this.item.acf_det_sker_link &&
        this.item.acf_det_sker_link.length > 0
        ? this.options.dictionary.visitWebsite
        : this.options.dictionary.readMore;
    },
    linkIcon() {
      return this.item.acf_det_sker_link &&
        this.item.acf_det_sker_link.length > 0
        ? `${this.baseUrl}/images/hit-external-arrow.svg`
        : `${this.baseUrl}/images/hit-arrow.svg`;
    },
    dateString() {
      if (this.item.acf_start_day && this.item.acf_end_day) {
        const options = {
          day: "numeric",
          month: "short",
        };
        const startDate = new Date(this.item.acf_start_day * 1000);
        const startDateString = startDate.toLocaleString("DA-dk", options);
        const endDate = new Date(this.item.acf_end_day * 1000);
        const endDateString = endDate.toLocaleString("DA-dk", options);

        return startDateString !== endDateString
          ? `${startDateString} - ${endDateString}`
          : startDateString;
      }
      return null;
    },
  },
  methods: {
    hitOver() {
      this.$emit("hit-over", this.item.post_id);
    },
    hitClick() {
      if (this.item.acf_det_sker_link) {
        window.open(this.item.acf_det_sker_link, "_blank");
      } else {
        window.location.href = this.item.permalink;
      }
    },
    hitLocationClick(location) {
      window.location.href = `/global-lokation/${slugify(location)}`;
    },
  },
};
</script>
