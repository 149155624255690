<template>
  <AisStats>
    <!-- eslint-disable-next-line -->
    <a
      slot-scope="{ nbHits }"
      class="ka-link-button"
      :href="url"
      v-html="formatLabel(nbHits)"
    />
  </AisStats>
</template>

<script>
import qs from "qs";

export default {
  name: "Stats",
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    url() {
      return `${this.options.link}${
        this.options.includeParams ? "/?" + qs.stringify(this.query) : ""
      }`;
    },
  },
  methods: {
    formatLabel(hits) {
      return this.options.label.replace(
        "{{hits}}",
        `<span class="ka-link-button__hits">${hits}</span>`
      );
    },
  },
};
</script>
