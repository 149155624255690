<template>
  <div id="vf-algolia-filter">
    <AisInstantSearch
      :search-client="searchClient"
      :index-name="options.index"
      :routing="routing"
    >
      <AisConfigure v-bind="options.config" />

      <div class="ka-loader" v-if="!ready">Loading ...</div>
      <div class="ka-panels" v-if="ready">
        <!-- TOP -->
        <Panel type="top">
          <Group
            v-for="(group, index) in topGroups"
            :key="`top-group-${index}`"
            :class-name="group.className"
            :title="group.title"
            ><Item
              v-for="(item, index2) in group.items"
              :key="`top-group-${index}__item-${index2}`"
              :index="options.index"
              :type="item.type"
              :ais-options="item.aisOptions"
              :options="item.options"
              :class-name="item.className"
              :title="item.title"
              :query="query"
              :state-toggles="stateToggles"
              :hit-over-id="hitOverId"
              :on="item.on"
              :off="item.off"
              :base-url="options.baseurl"
              @hit-over="hitOver"
              @hit-out="hitOut"
              @toggle-state="toggleState"
          /></Group>
        </Panel>

        <!-- LEFT -->
        <Panel type="left">
          <Group
            v-for="(group, index) in leftGroups"
            :key="`left-group-${index}`"
            :class-name="group.className"
            :title="group.title"
            ><Item
              v-for="(item, index2) in group.items"
              :key="`left-group-${index}__item-${index2}`"
              :index="options.index"
              :type="item.type"
              :ais-options="item.aisOptions"
              :options="item.options"
              :class-name="item.className"
              :title="item.title"
              :query="query"
              :state-toggles="stateToggles"
              :hit-over-id="hitOverId"
              :on="item.on"
              :off="item.off"
              :base-url="options.baseurl"
              @hit-over="hitOver"
              @hit-out="hitOut"
              @toggle-state="toggleState"
          /></Group>
        </Panel>

        <!-- CENTER -->
        <Panel type="center">
          <Group
            v-for="(group, index) in centerGroups"
            :key="`center-group-${index}`"
            :class-name="group.className"
            :title="group.title"
            ><Item
              v-for="(item, index2) in group.items"
              :key="`center-group-${index}__item-${index2}`"
              :index="options.index"
              :type="item.type"
              :ais-options="item.aisOptions"
              :options="item.options"
              :class-name="item.className"
              :title="item.title"
              :query="query"
              :state-toggles="stateToggles"
              :hit-over-id="hitOverId"
              :on="item.on"
              :off="item.off"
              :base-url="options.baseurl"
              @hit-over="hitOver"
              @hit-out="hitOut"
              @toggle-state="toggleState"
          /></Group>
        </Panel>

        <!-- RIGHT -->
        <Panel type="right">
          <Group
            v-for="(group, index) in rightGroups"
            :key="`right-group-${index}`"
            :class-name="group.className"
            :title="group.title"
            ><Item
              v-for="(item, index2) in group.items"
              :key="`right-group-${index}__item-${index2}`"
              :index="options.index"
              :type="item.type"
              :ais-options="item.aisOptions"
              :options="item.options"
              :class-name="item.className"
              :title="item.title"
              :query="query"
              :state-toggles="stateToggles"
              :hit-over-id="hitOverId"
              :on="item.on"
              :off="item.off"
              :base-url="options.baseurl"
              @hit-over="hitOver"
              @hit-out="hitOut"
              @toggle-state="toggleState"
          /></Group>
        </Panel>
      </div>
    </AisInstantSearch>
  </div>
</template>

<script>
import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";
import algoliasearch from "algoliasearch/lite";
import qs from "qs";

import Panel from "./components/Panel";
import Group from "./components/Group";
import Item from "./components/Item";

export default {
  name: "App",
  components: {
    Panel,
    Group,
    Item,
  },
  data() {
    return {
      ready: true,
      queryString: window.location.search || "",
      query: qs.parse(window.location.search.substring(1)),
      hitOverId: null,
      stateToggles: {},
    };
  },
  computed: {
    options() {
      return window["vf-algolia-filter"];
    },
    routing() {
      return {
        router: historyRouter(),
        stateMapping: singleIndexMapping(this.options.index),
      };
    },
    searchClient() {
      return algoliasearch(this.options.app, this.options.key);
    },
    topGroups() {
      return this.options.layout.top.groups;
    },
    leftGroups() {
      return this.options.layout.left.groups;
    },
    centerGroups() {
      return this.options.layout.center.groups;
    },
    rightGroups() {
      return this.options.layout.right.groups;
    },
  },
  mounted() {
    window.setInterval(() => {
      if (window.location.search !== this.queryString) {
        this.queryString = window.location.search || "";
        this.query = qs.parse(window.location.search.substring(1));
        this.$forceUpdate();
      }
    }, 100);
  },
  methods: {
    hitOver(id) {
      this.hitOverId = id;
    },
    hitOut() {
      this.hitOverId = null;
    },
    toggleState(toggle) {
      const statesCopy = Object.assign({}, this.stateToggles);
      statesCopy[toggle.id] = toggle.value;
      this.stateToggles = statesCopy;
    },
  },
};
</script>
