<template>
  <div v-show="isVisible" :class="classes">
    <div v-if="isTitleVisible" class="ka-item__title">
      {{ title }}
    </div>
    <div class="ka-item__content">
      <!-- Algolia refinement list -->
      <AisRefinementList
        v-if="type === 'AisRefinementList'"
        v-bind="aisOptions"
      />

      <!-- Algolia Infinite hits -->
      <AisInfiniteHits v-if="type === 'AisInfiniteHits'" v-bind="aisOptions">
        <ul slot-scope="{ items }">
          <li v-for="item in items" :key="item.objectID">
            <div>{{ item.post_title }}</div>
          </li>
        </ul>
      </AisInfiniteHits>

      <!-- Algolia Clear refinements -->
      <AisClearRefinements
        v-if="type === 'AisClearRefinements'"
        v-bind="aisOptions"
      >
        <div slot-scope="{ canRefine, refine, createURL }">
          <a v-if="canRefine" :href="createURL()" @click.prevent="refine">
            {{ options.label }}
          </a>
        </div>
      </AisClearRefinements>

      <!-- Custom search box -->
      <SearchBox v-if="type === 'SearchBox'" :options="options" />

      <!-- Custom stats -->
      <Stats v-if="type === 'Stats'" :options="options" />

      <!-- Custom sort by -->
      <SortBy v-if="type === 'SortBy'" :options="options" />

      <!-- Custom Infinite hits -->
      <InfiniteHits
        v-if="type === 'InfiniteHits'"
        :base-url="baseUrl"
        :options="options"
        :hit-over-id="hitOverId"
        @hit-over="hitOver"
        @hit-out="$emit('hit-out')"
      />

      <!-- Custom Hits -->
      <Hits
        v-if="type === 'Hits'"
        :base-url="baseUrl"
        :options="options"
        :hit-over-id="hitOverId"
        @hit-over="hitOver"
        @hit-out="$emit('hit-out')"
      />

      <!-- Custom slider -->
      <SliderRange
        v-if="type === 'SliderRange'"
        :ais-options="aisOptions"
        :options="options"
        :query="query"
      />

      <!-- Custom date picker -->
      <DateRange
        v-if="type === 'DateRange'"
        :options="options"
        :query="query"
      />

      <!-- Custom button -->
      <LinkButton
        v-if="type === 'LinkButton'"
        :options="options"
        :query="query"
      />

      <!-- Custom Refinement select -->
      <RefinementSelect
        v-if="type === 'RefinementSelect'"
        :index="index"
        :ais-options="aisOptions"
        :options="options"
        :query="query"
      />

      <!-- Custom Map -->
      <Map
        v-if="type === 'Map'"
        :base-url="baseUrl"
        :options="options"
        :hit-over-id="hitOverId"
        @hit-over="hitOver"
        @hit-out="$emit('hit-out')"
      />

      <!-- State toggle -->
      <StateToggle
        v-if="type === 'StateToggle'"
        :options="options"
        :state-toggles="stateToggles"
        @toggle-state="toggleState"
      />

      <!-- Html -->
      <HtmlContent v-if="type === 'HtmlContent'" :options="options" />
    </div>
  </div>
</template>

<script>
import SearchBox from "./SearchBox";
import Stats from "./Stats";
import SortBy from "./SortBy";
import DateRange from "./DateRange";
import SliderRange from "./SliderRange";
import LinkButton from "./LinkButton";
import RefinementSelect from "./RefinementSelect";
import Map from "./Map";
import InfiniteHits from "./InfiniteHits";
import Hits from "./Hits";
import StateToggle from "./StateToggle";
import HtmlContent from "./HtmlContent";

export default {
  name: "Item",
  components: {
    SearchBox,
    Stats,
    SortBy,
    DateRange,
    SliderRange,
    LinkButton,
    RefinementSelect,
    Map,
    InfiniteHits,
    Hits,
    StateToggle,
    HtmlContent,
  },
  props: {
    index: { type: String, default: "" },
    type: { type: String, default: "" },
    className: { type: String, default: "" },
    title: { type: String, default: "" },
    on: { type: String, default: "" },
    off: { type: String, default: "" },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
    aisOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hitOverId: {
      type: Number,
      default: 0,
    },
    stateToggles: {
      type: Object,
      default: function () {
        return {};
      },
    },
    baseUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return {
        "ka-item": true,
        [this.className]: this.className.length > 0,
      };
    },
    isTitleVisible() {
      return this.title.length > 0;
    },
    isOn() {
      return this.stateToggles[this.on] !== false;
    },
    isOff() {
      return this.stateToggles[this.off] === true;
    },
    isVisible() {
      return this.isOff ? false : this.isOn;
    },
  },
  methods: {
    hitOver(id) {
      this.$emit("hit-over", id);
    },
    toggleState(toggle) {
      this.$emit("toggle-state", toggle);
    },
  },
};
</script>
