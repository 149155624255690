<template>
  <div class="ka-html-content">
    <div class="ka-html-content__content" v-html="options.content"></div>
  </div>
</template>

<script>
export default {
  name: "HtmlContent",
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
