var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ka-date-range"},[_c('AisNumericMenu',{attrs:{"attribute":_vm.options.end.attribute,"items":[{}]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
return _c('div',{staticClass:"ka-date-range__start"},[_c('DatePicker',{attrs:{"type":_vm.options.date,"lang":_vm.options.lang,"placeholder":_vm.options.start.placeholder,"format":_vm.options.format},on:{"input":function (value) {
            _vm.startDateChange(value, refine);
          }},model:{value:(_vm.startValue),callback:function ($$v) {_vm.startValue=$$v},expression:"startValue"}})],1)}}])}),_c('AisNumericMenu',{attrs:{"attribute":_vm.options.start.attribute,"items":[{}]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var refine = ref.refine;
return _c('div',{staticClass:"ka-date-range__end"},[_c('DatePicker',{attrs:{"type":_vm.options.date,"lang":_vm.options.lang,"placeholder":_vm.options.end.placeholder,"format":_vm.options.format},on:{"input":function (value) {
            _vm.endDateChange(value, refine);
          }},model:{value:(_vm.endValue),callback:function ($$v) {_vm.endValue=$$v},expression:"endValue"}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }