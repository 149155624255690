var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.options.multiple)?_c('AisRefinementList',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return _c('div',{},[_c('multiselect',_vm._b({ref:"multi",attrs:{"options":_vm.formatItems(items)},on:{"select":refine,"remove":refine},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
return [(values.length > 0)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" "+_vm._s(_vm.options.text.selected))]):_vm._e()]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'multiselect',_vm.componentOptions,false),[(_vm.options.showAll)?_c('template',{slot:"beforeList"},[_c('AisClearRefinements',{attrs:{"included-attributes":_vm.clearAttribute},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
return [_c('div',{staticClass:"refinement-select__show-all",on:{"click":function($event){$event.preventDefault();return _vm.clearMultiClick(refine)}}},[_vm._v(" "+_vm._s(_vm.options.showAll)+" ")])]}}],null,true)})],1):_vm._e()],2)],1)}}],null,false,2436620147)},'AisRefinementList',_vm.aisOptions,false)):_vm._e(),(!_vm.options.multiple)?_c('AisMenu',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var refine = ref.refine;
return _c('div',{},[_c('multiselect',_vm._b({ref:"single",attrs:{"options":_vm.formatItems(items)},on:{"select":refine,"remove":refine},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'multiselect',_vm.componentOptions,false),[(_vm.options.showAll)?_c('template',{slot:"beforeList"},[_c('AisClearRefinements',{attrs:{"included-attributes":_vm.clearAttribute},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
return [_c('div',{staticClass:"refinement-select__show-all",on:{"click":function($event){$event.preventDefault();return _vm.clearSingleClick(refine)}}},[_vm._v(" "+_vm._s(_vm.options.showAll)+" ")])]}}],null,true)})],1):_vm._e()],2)],1)}}],null,false,1252676933)},'AisMenu',_vm.aisOptions,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }