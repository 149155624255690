<template>
  <ol v-if="state" class="ka-infinite-hits">
    <Hit
      v-for="hit in state.hits"
      :key="hit.objectID"
      :base-url="baseUrl"
      :item="hit"
      :options="options"
      :is-over="isHitOverId(hit.post_id)"
      @hit-over="hitOver"
      @hit-out="$emit('hit-out')"
    />
    <li v-observe-visibility="visibilityChanged" class="sentinel" />
  </ol>
</template>

<script>
import { createWidgetMixin } from "vue-instantsearch";
import { connectInfiniteHits } from "instantsearch.js/es/connectors";
import Hit from "./Hit";

export default {
  name: "InfiniteHits",
  components: { Hit },
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  props: {
    baseUrl: { type: String, default: "" },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hitOverId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
    formatLabel(hits) {
      return this.options.label.replace(
        "{{hits}}",
        `<span class="ka-link-button__hits">${hits}</span>`
      );
    },
    hitOver(id) {
      this.$emit("hit-over", id);
    },
    isHitOverId(id) {
      return id === this.hitOverId;
    },
  },
};
</script>
