<template>
  <div class="ka-date-range">
    <!-- options.end and options.start are flipped on purpose,
    as the start datepicker needs to compare to the end
    and vice versa. -->
    <AisNumericMenu :attribute="options.end.attribute" :items="[{}]">
      <div slot-scope="{ refine }" class="ka-date-range__start">
        <DatePicker
          v-model="startValue"
          :type="options.date"
          :lang="options.lang"
          :placeholder="options.start.placeholder"
          :format="options.format"
          @input="
            (value) => {
              startDateChange(value, refine);
            }
          "
        ></DatePicker>
      </div>
    </AisNumericMenu>
    <AisNumericMenu :attribute="options.start.attribute" :items="[{}]">
      <div slot-scope="{ refine }" class="ka-date-range__end">
        <DatePicker
          v-model="endValue"
          :type="options.date"
          :lang="options.lang"
          :placeholder="options.end.placeholder"
          :format="options.format"
          @input="
            (value) => {
              endDateChange(value, refine);
            }
          "
        ></DatePicker>
      </div>
    </AisNumericMenu>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "DateRange",
  components: { DatePicker },
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return { startValue: null, endValue: null };
  },
  watch: {
    query: {
      handler: function (query) {
        this.stateFromQuery(query);
      },
      immediate: true,
    },
  },
  mounted() {
    this.stateFromQuery(this.query);
  },
  methods: {
    stateFromQuery(query) {
      // Start
      const start =
        query &&
        query.numericMenu &&
        query.numericMenu[this.options.end.attribute];

      const startStamp = start && start.replace(":", "");
      this.startValue = startStamp ? new Date(startStamp * 1000) : null;

      // End
      const end =
        query &&
        query.numericMenu &&
        query.numericMenu[this.options.start.attribute];

      const endStamp = end && end.replace(":", "");
      this.endValue = endStamp ? new Date(endStamp * 1000) : null;
    },
    startDateChange(date, refine) {
      let config = {};
      if (date) {
        const time = new Date(date).getTime() / 1000;
        config = { start: time };
      }
      const value = JSON.stringify(config);
      refine(value);
    },
    endDateChange(date, refine) {
      let config = {};
      if (date) {
        const time = new Date(date).getTime() / 1000;
        config = { end: time + 86399 }; // Adds 23 hours 59 minutes and 59 seconds, to include the whole day
      }
      const value = JSON.stringify(config);
      refine(value);
    },
  },
};
</script>
