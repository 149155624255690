var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AisSortBy',{attrs:{"items":_vm.options.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var currentRefinement = ref.currentRefinement;
var refine = ref.refine;
return _c('ul',{staticClass:"ka-sort-by"},_vm._l((items),function(item){return _c('li',{key:item.value,class:{
        'ka-sort-by__item': true,
        'ka-sort-by__item--active': item.value === currentRefinement,
      },attrs:{"value":item.value},on:{"click":function($event){$event.preventDefault();return refine(item.value)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }