import Vue from "vue";
import App from "./App.vue";
import InstantSearch from "vue-instantsearch";
import Multiselect from "vue-multiselect";
import VueObserveVisibility from "vue-observe-visibility";
import VueLazyload from "vue-lazyload";

import "leaflet/dist/leaflet.css";

Vue.component("multiselect", Multiselect);
Vue.use(InstantSearch);
Vue.use(VueObserveVisibility);
Vue.use(VueLazyload);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#vf-algolia-filter");
