<template>
  <div :class="classes" @click="toggle">
    <span class="ka-state-toggle__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "StateToggle",
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    stateToggles: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    classes() {
      return {
        "ka-state-toggle": true,
        "ka-state-toggle--on": this.stateValue,
        "ka-state-toggle--off": !this.stateValue,
      };
    },
    stateValue() {
      return this.stateToggles[this.options.id];
    },
    label() {
      return this.stateValue ? this.options.on.label : this.options.off.label;
    },
  },
  mounted() {
    this.toggleValue(false);
  },
  methods: {
    toggle() {
      this.toggleValue(!this.stateValue);
    },
    toggleValue(value) {
      this.$emit("toggle-state", {
        id: this.options.id,
        value: value,
      });
    },
  },
};
</script>
