<template>
  <div>
    <AisRefinementList v-if="options.multiple" v-bind="aisOptions">
      <div slot-scope="{ items, refine }">
        <multiselect
          ref="multi"
          v-model="value"
          :options="formatItems(items)"
          v-bind="componentOptions"
          @select="refine"
          @remove="refine"
        >
          <template slot="selection" slot-scope="{ values }"
            ><span class="multiselect__single" v-if="values.length > 0"
              >{{ values.length }} {{ options.text.selected }}</span
            ></template
          >
          <template slot="beforeList" v-if="options.showAll">
            <AisClearRefinements :included-attributes="clearAttribute">
              <template v-slot="{ refine }">
                <div
                  class="refinement-select__show-all"
                  @click.prevent="clearMultiClick(refine)"
                >
                  {{ options.showAll }}
                </div>
              </template>
            </AisClearRefinements>
          </template>
        </multiselect>
      </div>
    </AisRefinementList>

    <AisMenu v-if="!options.multiple" v-bind="aisOptions">
      <div slot-scope="{ items, refine }">
        <multiselect
          ref="single"
          v-model="value"
          :options="formatItems(items)"
          v-bind="componentOptions"
          @select="refine"
          @remove="refine"
        >
          <template slot="beforeList" v-if="options.showAll">
            <AisClearRefinements :included-attributes="clearAttribute">
              <template v-slot="{ refine }">
                <div
                  class="refinement-select__show-all"
                  @click.prevent="clearSingleClick(refine)"
                >
                  {{ options.showAll }}
                </div>
              </template>
            </AisClearRefinements>
          </template>
        </multiselect>
      </div>
    </AisMenu>
  </div>
</template>

<script>
export default {
  name: "RefinementSelect",
  props: {
    index: { type: String, default: "" },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
    aisOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    query: {
      handler: function (query) {
        this.stateFromQuery(query);
      },
      immediate: true,
    },
  },
  mounted() {
    this.stateFromQuery(this.query);
  },
  computed: {
    componentOptions() {
      const compOptions = Object.assign({}, this.options);
      if (compOptions.hidden) {
        delete compOptions.hidden;
      }
      if (compOptions.showAll) {
        delete compOptions.showAll;
      }

      return compOptions;
    },
    clearAttribute() {
      return [this.aisOptions.attribute];
    },
  },
  methods: {
    formatItems(items) {
      if (this.options.hidden) {
        return items
          .filter((item) => {
            const index = this.options.hidden.indexOf(item.label);
            return index < 0;
          })
          .map((item) => {
            return item.label;
          });
      }
      return items.map((item) => {
        return item.label;
      });
    },
    clearMultiClick(refine) {
      this.$refs.multi.isOpen = false;
      refine();
    },
    clearSingleClick(refine) {
      this.$refs.single.isOpen = false;
      refine();
    },
    stateFromQuery(query) {
      if (this.options.multiple) {
        const valuesMulti =
          query &&
          query.refinementList &&
          query.refinementList[this.aisOptions.attribute];

        this.value = valuesMulti;
      } else {
        const valuesSingle =
          query && query.menu && query.menu[this.aisOptions.attribute];

        this.value = valuesSingle;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
