<template>
  <div class="ka-search-box">
    <AisSearchBox v-bind="aisOptions">
      <div slot-scope="{ isSearchStalled, refine }">
        <input
          type="search"
          v-model="searchFieldValue"
          :placeholder="options.placeholder"
          class="ka-search-box__input"
          @input="
            (event) => {
              performSearch(refine, event.currentTarget.value);
            }
          "
        />
        <span :hidden="!isSearchStalled">Loading...</span>
      </div>
    </AisSearchBox>
  </div>
</template>

<script>
import qs from "qs";

export default {
  name: "SearchBox",
  props: {
    aisOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      timeout: null,
      searchFieldValue: "",
    };
  },
  mounted() {
    const queryString = qs.parse(window.location.search.substring(1));
    const query = queryString.query;
    if (query) {
      this.searchFieldValue = query;
    }
  },
  methods: {
    performSearch(refine, query) {
      // Clear current timeout
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      // Set a new timeout for this input
      this.timeout = setTimeout(() => {
        this.timeout = null;

        // Only refine if query length above x or equal to 0
        if (query.length > this.options.minCharacters || query.length === 0) {
          refine(query);
        }
      }, this.options.typeDelay);
    },
  },
};
</script>
