<template>
  <AisSortBy :items="options.items">
    <ul slot-scope="{ items, currentRefinement, refine }" class="ka-sort-by">
      <li
        v-for="item in items"
        :key="item.value"
        :class="{
          'ka-sort-by__item': true,
          'ka-sort-by__item--active': item.value === currentRefinement,
        }"
        :value="item.value"
        @click.prevent="refine(item.value)"
      >
        {{ item.label }}
      </li>
    </ul>
  </AisSortBy>
</template>

<script>
export default {
  name: "SortBy",
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {},
};
</script>
